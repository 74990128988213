import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";

import TableLayout from "../../../layouts/tables/TableLayout";
import StaffModal from "../../../components/modals/table modals/admin/StaffModal";
import CustomPagination from "../../../components/tables/CustomPagination";
import SelectSearch from "../../../components/search inputs/SelectSearch";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import InputSearch from "../../../components/search inputs/InputSearch";
import useDeleteStaff from "../../../services/hooks/delete-staff-user";
import useFetchStaff from "../../../services/hooks/fetch-staff-users";
import StaffTable from "../../../components/tables/admin/StaffTable";
import NoTableData from "../../../components/tables/NoTableData";
import { roleOptions } from '../../../utils/constants';

const AdminManageStaff = () => {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const { isDeleting, deleteStaffUser } = useDeleteStaff();
  const [initialValues, setInitialValues] = useState(emptyUser);
  const {
    isFetching,
    fetchAllStaffUsers,
    data,
    onSearch,
    onSearchInput,
    onPageChange,
    searchParams,
  } = useFetchStaff();

  const closeModal = () => {
    setModalTitle("");
    setInitialValues(emptyUser);
    setOpen(false);
  };

  const editUserHandler = (prop) => {
    setInitialValues(prop);
    setModalTitle("Edit " + prop.firstName + " " + prop.lastName);
    setOpen(true);
  };

  const createUserHandler = () => {
    setInitialValues(emptyUser);
    setModalTitle("Add New Staff");
    setOpen(true);
  };

  const deleteUserHandler = async (id) => {
    await deleteStaffUser(id);
    fetchAllStaffUsers(data.page);
  };

  useEffect(() => {
    fetchAllStaffUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StaffModal
        open={open}
        closeModal={closeModal}
        reload={() => fetchAllStaffUsers(data.page)}
        initialValues={initialValues}
        title={modalTitle}
      />
      <DashboardLayout>
        <Box className='manage-staff-container'>
          <h1 className='page-title'>Manage Users</h1>
          <div className='search-options'>
            <div className='inputs'>
              <SelectSearch
                id='roleRange'
                className='selectBox'
                width={150}
                label='Role'
                value={searchParams?.role || ''}
                selectItems={roleOptions}
                onSelect={onSearchInput("role")}
              />
              <InputSearch
                className='inputSearch'
                width={400}
                placeholder='Search by name ...'
                onChange={onSearchInput("name")}
              />
            </div>
            <div className='buttons'>
              <Box className='action-buttons' sx={{ width: "150px", height: "40px" }}>
                <Button
                  sx={{ width: "100%", height: "100%" }}
                  variant='outlined'
                  disableElevation
                  onClick={onSearch}
                  disabled={isFetching}
                >
                  Search
                </Button>
              </Box>
              <Box className='action-buttons' sx={{ height: "40px" }}>
                <Button
                  sx={{ width: "100%", height: "100%" }}
                  className='invite-user-button'
                  variant='contained'
                  disableElevation
                  onClick={createUserHandler}
                  startIcon={<AddIcon />}
                  disabled={isDeleting || isFetching}
                >
                  Invite New User
                </Button>
              </Box>
            </div>
          </div>
          <TableLayout isLoading={isFetching}>
            {data.entries?.length > 0 && (
              <StaffTable
                title='Staff Users'
                tableRows={data.entries}
                onEdit={editUserHandler}
                onDelete={deleteUserHandler}
                isLoading={isDeleting}
              />
            )}
            {data.entries?.length < 1 && (
              <NoTableData
                text='To add to please '
                link='Create Staff User'
                onClick={createUserHandler}
              />
            )}
            {data.entries?.length > 0 && data.totalPages > 1 && (
              <CustomPagination
                paginationHandler={onPageChange}
                totalPages={data.totalPages}
                page={data.page}
              />
            )}
          </TableLayout>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default AdminManageStaff;

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  role: "",
};


