import axios from "axios";
import { useEffect } from "react";

import store from "../store";
import { decrypt, encrypt } from "../utils/security";
import { useRefreshToken } from "../services/hooks/useRefreshToken";
import { alertError, generateHeader } from "../utils/helperFunctions";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/api",
});

Axios.interceptors.request.use(
  (request) => {
    const isLoggedIn = store.getState().userAuth.isLoggedIn;
    const token = store.getState().userAuth?.data?.tokenResponse?.token;

    let req = request;

    if (!navigator.onLine) {
      alertError({ message: "No Internet Connection" });
      return;
    }

    if (isLoggedIn) {
      req.headers = {
        ...req.headers,
        ...generateHeader(),
        Authorization: token,
      };
    } else {
      req.headers = { ...req.headers, ...generateHeader() };
      delete req.headers["Authorization"];
    }

    if (req.data && !req?.params?.bypassEncryption) {
      req.data = { encryptedRequest: encrypt(request.data) };
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AxiosInterceptor = ({ children }) => {
  const refreshToken = useRefreshToken();

  useEffect(() => {
    const resInterceptor = (response) => {
      let res = response;
      res.data = JSON.parse(decrypt(response.data.encryptedResponse));

      return res;
    };

    const resErrInterceptor = async (error) => {
      const originalRequest = error.config;

      // Handle token expiration and retry the request with a refreshed token
      if (
        error.response &&
        error.response.status === 401 &&
        Number(error.response.data.code) === 56 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const isSuccess = await refreshToken();
        if (isSuccess) {
          return Axios(originalRequest);
        }
        return;
      }

      return Promise.reject(error);
    };

    const responseInterceptor = Axios.interceptors.response.use(resInterceptor, resErrInterceptor);

    return () => Axios.interceptors.response.eject(responseInterceptor);
  }, [refreshToken]);

  return children;
};

export default Axios;
export { AxiosInterceptor };
