import "../index.css";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../layouts/tables/TableLayout";

import { numberWithCommas } from "../../../utils/helperFunctions";
import SelectSearch from "../../search inputs/SelectSearch";
import InputSearch from "../../search inputs/InputSearch";
import DateRange from "../../search inputs/DateRange";
import CustomTable from "../CustomTable";

const IncomeTable = (props) => {
  const [searchParams, setSearchParams] = useState({});

  const searchParamsHandler = (prop) => (e) => {
    setSearchParams({
      ...searchParams,
      [prop]: e.target.value,
    });
  };

  const searchDateParamHandler = (prop, date) => {
    setSearchParams({
      ...searchParams,
      [prop]: date,
    });
  };

  const handleDateReset = () => {
    delete searchParams?.startDate;
    delete searchParams?.endDate;
  };

  const searchHandler = () => {
    if (searchParams?.searchBy?.length === 0) {
      delete searchParams.searchBy;
    }
    if (searchParams.period !== "custom") {
      delete searchParams?.startDate;
      delete searchParams?.endDate;
    }
    if (!!!searchParams.period) delete searchParams?.period;

    props.handleSearch(searchParams);
  };

  return (
    <Box className="income-table">
      <div className="table-top flex-col lg:flex-row gap-3 items-start lg:items-center">
        <h1 className="table-title">Income</h1>
        <div className="search-options flex-wrap w-full">
          <div className="inputs flex-wrap justify-end">
            <InputSearch
              className="inputSearch"
              width={250}
              placeholder="Search by ref..."
              onChange={searchParamsHandler("searchBy")}
            />

            <SelectSearch
              id="periodRange"
              className="selectBox flex-grow lg:flex-grow-0"
              width={150}
              label="Period"
              value={searchParams.period || ""}
              selectItems={periodOptions}
              resettable
              onSelect={searchParamsHandler("period")}
            />
            {searchParams?.period === "custom" && (
              <DateRange
                className="dateRange w-full sm:w-auto flex-grow lg:flex-grow-0"
                width={150}
                setDate={searchDateParamHandler}
                onReset={handleDateReset}
              />
            )}
          </div>
          <Box
            className="action-buttons flex-grow lg:flex-grow-0"
            sx={{ width: { xs: "100%", sm: "150px" }, height: "40px" }}
          >
            <Button
              sx={{ width: "100%", height: "100%" }}
              variant="outlined"
              disableElevation
              onClick={searchHandler}
              disabled={props.isDownloading || props.isLoading}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
      <CustomTable headers={tableHeads}>
        {props.tableRows.map((row, index) => (
          <StyledTableRow props={props} hover key={index}>
            <StyledTableCell component="th" scope="row">
              {row?.referenceId}
            </StyledTableCell>
            <StyledTableCell>{row?.customerName}</StyledTableCell>
            <StyledTableCell>{row?.transactionDate}</StyledTableCell>
            <StyledTableCell>{row?.currency?.toUpperCase()} {numberWithCommas(row?.amount)}</StyledTableCell>
            <StyledTableCell>{row?.currency?.toUpperCase()} {numberWithCommas(row?.settledAmount)}</StyledTableCell>
          </StyledTableRow>
        ))}
      </CustomTable>
    </Box>
  );
};

export default IncomeTable;

const tableHeads = [
  "Ref ID",
  "Customer",
  "Transaction Date",
  "Received Amount",
  "Settled Amount",
];

const periodOptions = [
  {
    value: "OneWeek",
    text: "1 Week",
  },
  {
    value: "OneMonth",
    text: "1 Month",
  },
  {
    value: "SixMonths",
    text: "6 Months",
  },
  {
    value: "custom",
    text: "Custom Range",
  },
];
