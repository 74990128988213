import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthAdminMerchant = () => {
  const { role, userType } = useSelector((state) => state?.userAuth?.data);

  const isAdmin = role?.toLowerCase()?.includes("admin");
  const isMerchant = userType?.toLowerCase()?.includes("merchant");

  return isMerchant && isAdmin ? <Outlet /> : <Navigate to="/overview" />;
};

export default AuthAdminMerchant;
