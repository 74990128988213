import { useCallback, useState } from "react";
import { fetchAllStaff } from "../actions/staffAdmin.actions";
import { alertError } from "../../utils/helperFunctions";
import { scrollToTableTop } from "../../layouts/tables/TableLayout";

const useFetchStaff = () => {
  const [searchParams, setSearchParams] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    entries: [],
    page: 1,
    totalPages: 0,
  });

  const fetchAllStaffUsers = useCallback(
    async (page = 1) => {
      setIsFetching(true);
      try {
        const response = await fetchAllStaff(searchParams, page);
        if (response) {
          setData({
            entries: response?.data || [],
            page: response?.currentPageNumber || 1,
            totalPages: response?.totalPages || 0,
          });
        }
      } catch (error) {
        setError(error);
        alertError(error);
      } finally {
        setIsFetching(false);
      }
    },
    [searchParams]
  );

  const onSearchInput = (prop) => (e) => {
    setSearchParams({
      ...searchParams,
      [prop]: e.target.value,
    });
  };

  const onSearch = () => {
    if (searchParams?.name?.length < 1) {
      delete searchParams.name;
    }
    fetchAllStaffUsers();
  };

  const onPageChange = (page) => {
    scrollToTableTop();
    fetchAllStaffUsers(page);
  };

  return {
    isFetching,
    error,
    data,
    fetchAllStaffUsers,
    onSearch,
    onPageChange,
    onSearchInput,
    searchParams,
  };
};

export default useFetchStaff;
