import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import InnerReportsLayout from "./InnerReportsLayer";

const Reports = () => {
  return (
    <DashboardLayout>
      <InnerReportsLayout />
    </DashboardLayout>
  );
};

export default Reports;
