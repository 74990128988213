import { useEffect } from "react";
import { Button } from "@mui/material";

import SelectSearch from "../../../components/search inputs/SelectSearch";
import useLockout from "../../../services/hooks/lockout-time";
import { availableTime } from "../../../utils/constants";
import Spinner from "../../../utils/Spinner";

const StandardHours = () => {
  const { isLoading, setLockout, fetchLockoutTime, time, setTime } = useLockout();

  const handleSelect = (prop) => (e) => {
    setTime({
      ...time,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setLockout(time);
    fetchLockoutTime();
  };

  useEffect(() => {
    fetchLockoutTime();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className='flex flex-col w-fit gap-10' onSubmit={handleSubmit}>
      <div className='flex items-center gap-2'>
        <SelectSearch
          label='Set Start time'
          value={time.startTime}
          width={150}
          selectItems={availableTime?.filter((t) => t.value < time.endTime)}
          onSelect={handleSelect("startTime")}
          isDisabled={isLoading}
        />
        -
        <SelectSearch
          label='Set End time'
          value={time.endTime}
          width={150}
          selectItems={availableTime?.filter((t) => t.value > time.startTime)}
          onSelect={handleSelect("endTime")}
          isDisabled={isLoading}
        />
      </div>
      <Button
        type='submit'
        className='w-full h-11'
        color='primary'
        variant='contained'
        disableElevation
        disabled={isLoading}
      >
        {isLoading && <Spinner size='24px' color='primary' />}
        Set Time
      </Button>
    </form>
  );
};

export default StandardHours;
