import DocxTable from "../DocxTable";

const Test = () => {
  return (
    <>
      <h1 className="section-header">Testing</h1>
      <p>
        For testing purposes, please use the credentials provided for each
        payment method below
      </p>
      <div className="pay-with-card flex flex-col gap-4">
        <h3 className="section-sub-header">Pay With Card</h3>
        <ul className="flex flex-col gap-5 list-none ml-5">
          <li>
            <div className="flex flex-col gap-5">
              <p className="font-[600]">Mastercard and Visa Card</p>
              <DocxTable
                rows={accountRows}
                headers={accountHeaders}
                minWidth={500}
              />
              <DocxTable
                rows={expiryRows}
                headers={expiryHeaders}
                minWidth={500}
              />
              <DocxTable rows={cvvRows} headers={cvvHeaders} minWidth={500} />
              <p>
                The same process should be repeated for different scenarios such
                as successful payments, failed payments, declined payments (due
                to wrong parameters like CVV, and expired card).
              </p>
            </div>
          </li>
          <li>
            <div className="flex flex-col gap-2">
              <p className="font-[600]">Verve Card</p>
              <DocxTable rows={verveRows} headers={verveHeaders} />
            </div>
          </li>
        </ul>
        <p></p>
      </div>
      <div className="pay-with-bank flex flex-col gap-4">
        <h3 className="section-sub-header">Pay With Bank</h3>
        <ul className="flex flex-col gap-3 list-decimal ml-5">
          <li>
            <div className="flex flex-col gap-2">
              <p>Login using the following credentials</p>
              <ul className="ml-8 list-disc">
                <li>
                  <span className="inline-block font-[600] mb-2">
                    Username:
                  </span>{" "}
                  test
                </li>
                <li>
                  <span className="inline-block font-[600] mb-2">
                    Password:
                  </span>{" "}
                  test@123
                </li>
                <li>
                  <span className="inline-block font-[600] mb-2">
                    Security answer:
                  </span>{" "}
                  test
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="flex flex-col gap-2">
              <p>
                Use the following account details to simulate both failed and
                success transaction scenarios
              </p>
              <ul className="ml-8 list-disc">
                <li>
                  <span className="inline-block font-[600] mb-2">
                    Account Number:
                  </span>{" "}
                  1234567890 (sufficient funds)
                </li>
                <li>
                  <span className="inline-block font-[600] mb-2">
                    Account Number:
                  </span>{" "}
                  1234567891 (insufficient funds)
                </li>
                <li>
                  <span className="inline-block font-[600] mb-2">OTP:</span>{" "}
                  123456
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <p>
          The same process should be repeated for different scenarios such as
          failed payments, declined payments (due to wrong OTP wrong username,
          password or security answer), insufficient funds, account
          restrictions, etc.
        </p>
      </div>
      <div className="pay-later flex flex-col gap-4">
        <h3 className="section-sub-header">Pay Later</h3>
        <ul className="ml-8 list-disc">
          <li>
            <span className="inline-block font-[600] mb-2">BVN:</span>{" "}
            12345678901 (correct and eligible)
          </li>
          <li>
            <span className="inline-block font-[600] mb-2">BVN:</span>{" "}
            98765432198 (correct and ineligible)
          </li>
          <li>
            <span className="inline-block font-[600] mb-2">OTP:</span> 123456
          </li>
        </ul>
        <p>
          The same process should be repeated for different scenarios such as
          failed payments, declined payments (due to wrong OTP, invalid BVN,
          failed eligibility), account restrictions, etc
        </p>
      </div>
    </>
  );
};

export default Test;

const accountRows = [
  {
    num: "5123450000000008",
    type: "Mastercard",
  },
  {
    num: "2223000000000007",
    type: "Mastercard",
  },
  {
    num: "4508750015741019",
    type: "Visa",
  },
];

const accountHeaders = ["Card Number", "Card Type"];

const expiryRows = [
  {
    date: "01 / 39",
    response: "APPROVED",
  },
  {
    date: "05 / 39",
    response: "DECLINED",
  },
  {
    date: "04 / 27",
    response: "EXPIRED_CARD",
  },
];

const expiryHeaders = ["Expiry Date", "Transaction Response"];

const cvvRows = [
  {
    cvv: "100",
    response: "MATCH",
  },
  {
    cvv: "any other 3 digits",
    response: "ERROR RESPONSE",
  },
];

const cvvHeaders = ["CSC/CVV", "CSC/CVV Response"];

const verveHeaders = ["Card Number", "Expiry", "CVV", "pin", "OTP", "Response"];

const verveRows = [
  {
    num: "5060990000000000008",
    date: "01 / 39",
    cvv: "100",
    pin: "1111",
    otp: "123456",
    response: "Success",
  },
  {
    num: "5060990000000000008",
    date: "01 / 39",
    cvv: "100",
    pin: "1111",
    otp: "100000",
    response: "Failure - Insufficient Funds",
  },
];
