import { useDispatch, useSelector } from "react-redux";

import { refreshAuthToken } from "../actions/authentication.actions";
import { userAuthActions } from "../reducers/userAuth.reducer";
import { alertError } from "../../utils/helperFunctions";
import { decrypt, encrypt } from "../../utils/security";
import useRedirect from "./useRedirect";

export const useRefreshToken = () => {
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const tokenResponse = useSelector((state) => state.userAuth?.data?.tokenResponse);

  const refreshTokenHandler = async () => {
    const { token, refreshToken } = tokenResponse;
    let currentUserData = JSON.parse(decrypt(localStorage.getItem("PGP")));
    try {
      const response = await refreshAuthToken(token, refreshToken);
      if (response) {
        const userData = {
          ...currentUserData,
          data: { ...currentUserData.data, tokenResponse: response.data },
        };
        localStorage.setItem("PGP", encrypt(JSON.stringify(userData)));

        dispatch(userAuthActions.update({ tokenResponse: response.data }));

        return true;
      }
    } catch (error) {
      alertError(error);
      redirect();
    }
  };

  return refreshTokenHandler;
};
