import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

// interface Props {
//     routes: {path?: string, name:string}[]
// }

const AppBreadcrumbs = ({ routes }) => {
  return (
    <Breadcrumbs
      separator=">"
      sx={{
        fontSize: "14px",
        fontFamily: "General Sans",
        letterSpacing: "0.02em",
      }}
    >
      {routes?.map((item, i) =>
        item.path ? (
          <Link key={i} to={item.path}>
            {item.name}
          </Link>
        ) : (
          <p key={i}>{item.name}</p>
        )
      )}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
