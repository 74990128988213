import { Stack, TextField } from "@mui/material";

const CustomerDetails = ({ details, onChange }) => {
  return (
    <Stack gap="25px">
      <p className="font-[600] text-black text-[20px]">Customer details</p>
      <Stack gap="20px">
        <TextField
          fullWidth
          name="email"
          id="outlined-basic"
          label="Email"
          placeholder="Enter your email"
          required
          variant="outlined"
          onChange={(e) => onChange(e)}
          value={details.email}
        />
        <TextField
          name="name"
          fullWidth
          id="outlined-basic"
          label="Customer Name"
          placeholder="Optional"
          variant="outlined"
          onChange={(e) => onChange(e)}
          value={details.name}
        />
      </Stack>
    </Stack>
  );
};

export default CustomerDetails;
