import { createSlice } from "@reduxjs/toolkit";

const systemReducer = createSlice({
  name: "systemControllers",
  initialState: {
    isLoading: false,
    isRefreshing: false,
  },
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    refreshToken(state) {
      state.isRefreshing = !state.isRefreshing;
    },
  },
});
export default systemReducer.reducer;
export const systemControllersActions = systemReducer.actions;
