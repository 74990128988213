import { createSlice } from "@reduxjs/toolkit";
import { decrypt } from "../../utils/security";

let authData = {
  isLoggedIn: false,
};
const encryptedData = localStorage.getItem("PGP");
if (encryptedData) {
  authData = JSON.parse(decrypt(encryptedData));
}

const userAuthReducer = createSlice({
  name: "userAuth",
  initialState: { ...authData },
  reducers: {
    logIn(state, action) {
      state.data = { ...action.payload.data };
      state.isLoggedIn = true;
    },
    logOut(state) {
      state.isLoggedIn = false;
      delete state.data;
    },
    update(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
  },
});
export default userAuthReducer.reducer;
export const userAuthActions = userAuthReducer.actions;
