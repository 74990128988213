// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/images/summary-card-design.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".summary-card-container .summary-card-inner {\n  background-color: #fafafa;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: bottom right;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/dispute/summary/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yDAAmE;EACnE,wBAAwB;EACxB,4BAA4B;EAC5B,iCAAiC;AACnC","sourcesContent":[".summary-card-container .summary-card-inner {\n  background-color: #fafafa;\n  background-image: url(\"/src/assets/images/summary-card-design.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: bottom right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
