import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { numberWithCommas } from "../../utils/helperFunctions";
import { dollar } from '../../utils/constants';

const OrderSummary = ({ summary, coupon, toggle, currency }) => {
  const symbol = currency === dollar ? "$" : "₦";

  return (
    <Stack gap="20px" className="text-black">
      <p className="font-[600] text-black text-[20px]">Order Summary</p>
      <Stack gap="15px">
        <Stack gap="10px" direction="row" justifyContent="space-between" alignItems="center">
          <p>Items subtotal:</p>
          <p>{symbol} {numberWithCommas(parseFloat(summary.subTotal))}</p>
        </Stack>
        <Stack gap="10px" direction="row" justifyContent="space-between" alignItems="center">
          <p>Delivery fees:</p>
          <p>
            {summary.subTotal < 1
              ? `${symbol} 0`
              : summary.subTotal > 500
              ? `${symbol} ${numberWithCommas(summary.delivery)}`
              : "Free Delivery"}
          </p>
        </Stack>
        <Stack gap="10px" direction="row" justifyContent="space-between" alignItems="center">
          <p>VAT (10%):</p>
          <p>{symbol} {numberWithCommas(parseFloat(summary.vat))}</p>
        </Stack>
        <FormControlLabel
          control={<Checkbox onClick={toggle} value={coupon} color="secondary" size="small" />}
          labelPlacement="start"
          label={<p className="font-['General Sans']">Apply 50% coupon</p>}
        />
        <Stack
          gap="10px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="font-[500]"
        >
          <p>Total:</p>
          <p>{symbol} {numberWithCommas(parseFloat(summary.total))}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderSummary;
