import { useState } from "react";
import { Box, Button, Modal } from "@mui/material";

import { ICONS } from "../../../../assets";
import Spinner from "../../../../utils/Spinner";
import SuccessModalContent from "./SuccessModalContent";
import { alertError } from "../../../../utils/helperFunctions";
import { deleteMerchant } from "../../../../services/actions/staffMakerChecker.actions";

const DeleteMerchant = ({ data, handleClose, goBack, open, header, subHeader }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setisLoading] = useState(false);

  const deleteAction = async () => {
    setisLoading(true);

    try {
      const result = await deleteMerchant(data?.accountNumber);
      if (result) {
        setStep(2);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  };

  const closeModal = () => {
    setStep(1);
    handleClose();
  };

  const done = () => {
    closeModal();
    goBack();
  };

  const closeHandler = (e, reason) => {
    if (step === 2 && reason && reason === "backdropClick") {
      return;
    }
    handleClose();
  };

  return (
    <div>
      <Modal open={open} onClose={closeHandler}>
        <Box className="modal-content">
          {step && step === 1 && (
            <div className="flex flex-col justify-center items-center">
              <img src={ICONS.bin} alt="check icon" className="w-[64px] h-[64px]" />
              <h3 className="font-medium text-lg mt-5">{header}</h3>
              {subHeader && <p className="text-[#928798] text-sm ">{subHeader}</p>}
              <div className="flex gap-4 mt-[3rem]">
                <Button
                  variant="outlined"
                  sx={{
                    height: "56px",
                    fontSize: "16px",
                    boxShadow: "none",
                    maxWidth: "100%",
                    width: "200px",
                  }}
                  onClick={closeHandler}
                  disabled={isLoading}
                >
                  No, Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "56px",
                    fontSize: "16px",
                    boxShadow: "none",
                    maxWidth: "100%",
                    width: "200px",
                  }}
                  onClick={deleteAction}
                >
                  {isLoading && <Spinner />}
                  Yes, Continue
                </Button>
              </div>
            </div>
          )}

          {step && step === 2 && (
            <SuccessModalContent
              closeModal={done}
              header="Deleted merchant sent for approval!"
              actionText="Done"
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteMerchant;
