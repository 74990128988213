import Axios from "../../config/Axios";
import { generateRequestId } from "../../utils/helperFunctions";

export const createDispute = async (payload) => {
  try {
    const response = await Axios.post("/disputes", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const createBatchDispute = async (payload) => {
  try {
    const response = await Axios.post("/disputes/batch", payload, {
      params: { bypassEncryption: true },
    });
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const getDisputeSummary = async () => {
  try {
    const response = await Axios("/disputes/dash");
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const getAllDisputes = async (params) => {
  try {
    const response = await Axios("/disputes?PageSize=15", { params });
    const data = await response?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const getSingleDispute = async (id) => {
  try {
    const response = await Axios(`/disputes/${id}`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const updateDisputeStatus = async (id, status) => {
  const payload = status;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.patch(`/disputes/${id}/status`, payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const addDisputeFile = async (id, payload) => {
  try {
    const response = await Axios.post(`/disputes/${id}/files`, payload, {
      params: { bypassEncryption: true },
    });
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const removeDisputeFile = async (id, fileId) => {
  try {
    const response = await Axios.delete(`/disputes/${id}/files/${fileId}`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const getDisputeComments = async (id) => {
  try {
    const response = await Axios(`/disputes/${id}/comments`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const addDisputeComment = async (id, payload) => {
  try {
    const response = await Axios.post(`/disputes/${id}/comments`, payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};

export const removeDisputeComment = async (id, commentId) => {
  try {
    const response = await Axios.delete(`/disputes/${id}/comments/${commentId}`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.description || error?.message);
  }
};
