import { useSelector } from "react-redux";

import useRedirect from "./useRedirect";
import { userLogout } from "../actions/authentication.actions";

const useLogout = () => {
  const redirect = useRedirect();
  const token = useSelector(
    (state) => state?.userAuth?.data?.tokenResponse?.token
  );
  const refreshToken = useSelector(
    (state) => state?.userAuth?.data?.tokenResponse?.refreshToken
  );

  const logoutUser = async () => {
    try {
      const response = await userLogout(token, refreshToken);
      if (response) {
        redirect();
      }
    } catch (error) {
      redirect();
      throw new Error(error.response?.data?.data);
    }
  };

  return logoutUser;
};

export default useLogout;
