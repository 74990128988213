import "./index.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Box, LinearProgress, Button } from "@mui/material";

import CustomTable from "./CustomTable";
import ExportButton from "../buttons/ExportButton";
import { numberWithCommas } from "../../utils/helperFunctions";
import { StyledTableCell, StyledTableRow } from "../../layouts/tables/TableLayout";
import { paymentChannel, paymentStatus } from "../../utils/constants";

const TransactionsTable = (props) => {
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const isStaff = userType?.toLowerCase() === "staff";
  const navigate = useNavigate();

  const tableHeads = [
    "Transaction Reference",
    isStaff ? "Merchant ID" : null,
    "Amount",
    "Customer Email",
    "Channel",
    "Transaction Date",
    "Status",
    !props.showDownload ? "" : null,
  ];

  const viewHandler = (prop) => (e) => {
    navigate("/transactions/" + prop?.transactionRef?.toLowerCase(), {
      state: prop,
    });
  };

  return (
    <Box className="transaction-table">
      {props.showPagination && props.isLoading && <LinearProgress />}
      <div className="table-top">
        {!props.recent && (
          <div className="w-full flex items-center justify-between">
            <h1 className="table-title all">
              All Transactions{props.count && <span>({props.count})</span>}
            </h1>
            {(!isStaff || props.showDownload) && (
              <Box sx={{ width: "140px" }}>
                <ExportButton
                  onExport={props.onExport}
                  disabled={props.isLoading}
                  isLoading={props.isDownloading}
                />
              </Box>
            )}
          </div>
        )}
        {props.recent && (
          <>
            <h1 className="table-title recent">Recent Transactions</h1>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{ width: "100px", height: "40px" }}
              onClick={() => navigate("/transactions")}
            >
              View All
            </Button>
          </>
        )}
      </div>
      <CustomTable headers={tableHeads}>
        {props.tableRows?.map((row, index) => (
          <StyledTableRow props={props} hover key={index}>
            <StyledTableCell component="th" scope="row">
              {row?.transactionRef}
            </StyledTableCell>
            {isStaff && <StyledTableCell>{row?.merchantCode}</StyledTableCell>}
            <StyledTableCell>{row?.currency?.toUpperCase()} {numberWithCommas(row?.amount)}</StyledTableCell>
            <StyledTableCell>{row?.customerEmail?.toLowerCase()}</StyledTableCell>
            <StyledTableCell>{paymentChannel?.[row?.channel]}</StyledTableCell>
            <StyledTableCell>{row?.transactionDate}</StyledTableCell>
            <StyledTableCell id={paymentStatus?.[row?.status]?.toLowerCase()}>
              {paymentStatus?.[row?.status]}
            </StyledTableCell>
            {!props.showDownload && (
              <StyledTableCell align="right">
                <span id="view" onClick={viewHandler(row)}>
                  view
                </span>
              </StyledTableCell>
            )}
          </StyledTableRow>
        ))}
      </CustomTable>
    </Box>
  );
};

export default TransactionsTable;
