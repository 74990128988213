import { useEffect } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

import InnerSettingsLayout from "../../../layouts/dashboard/settings/InnerSettingsLayout";

const AdminSettings = () => {
  const navigate = useNavigate();
  const nameUrl = useLocation();
  const route = [{ path: "/settings/" }];

  const links = [
    {
      linkName: "General",
      path: "general",
    },
    {
      linkName: "Notifications",
      path: "notifications",
    },
  ];

  useEffect(() => {
    if (matchRoutes(route, nameUrl)) {
      navigate("general", { replace: true });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="settings-container">
        <h1 className="page-title">Settings</h1>
        <InnerSettingsLayout links={links} />
      </div>
    </>
  );
};

export default AdminSettings;
