import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useDispute from "../../../../services/hooks/useDispute";

const CommentCard = ({ comment, disputeId, onDelete }) => {
  const { deleteComment, isCommenting } = useDispute();
  const timeSinceCreated = (moment() - moment(comment.dateCreated)) / (1000 * 60); // in minutes
  const userType = useSelector((state) => state?.userAuth?.data?.userType);
  const [canDelete, setCanDelete] = useState(timeSinceCreated < 1);
  const isAuthor = userType === comment.authorType;

  if (canDelete) {
    setTimeout(() => {
      setCanDelete(false);
    }, (1 - timeSinceCreated) * 60 * 1000);
  }

  const handleDelete = async () => {
    const res = await deleteComment(disputeId, comment.id);
    if (res) {
      onDelete(disputeId);
    }
  };

  return (
    <div
      className={`flex items-start group gap-2 max-w-[85%] ${
        isAuthor ? "self-end flex-row-reverse" : "self-start"
      } ${isCommenting ? "opacity-60 pointer-events-none" : ""}`}
    >
      <div
        className={`flex flex-col box-border p-2 gap-1 relative rounded-md border bg-opacity-30 border-opacity-10 ${
          isAuthor ? "bg-grey border-gray" : "bg-gray border-[grey]"
        }`}
      >
        <p className="font-medium text-black tracking-wide flex items-center gap-2">
          <span className="text-xs font-normal opacity-30">
            {moment(comment.dateCreated).fromNow()}
          </span>
        </p>
        <p className="text-sm text-black opacity-90">{comment.content}</p>
      </div>
      {isAuthor && canDelete && (
        <div className="min-w-[28px] max-w-[28px]">
          <div className="hidden group-hover:flex">
            <IconButton aria-label="delete" color="error" size="small" onClick={handleDelete}>
              <RemoveCircleOutlineIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentCard;
