import { useCallback, useState } from "react";
import { alertError, alertSuccess } from "../../utils/helperFunctions";
import { fetchLockOutTime, setLockOutTime } from "../actions/staffAdmin.actions";

const useLockout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState({
    startTime: "",
    endTime: "",
  });

  const fetchLockoutTime = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchLockOutTime();
      if (response) {
        setTime(response);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const setLockout = useCallback(async (time) => {
    setIsLoading(true);
    try {
      await setLockOutTime(time);
      alertSuccess("Lockout time set successfully");
    } catch (error) {
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, setLockout, time, fetchLockoutTime,setTime };
};

export default useLockout;
