import "./index.css";
import { Box, Dialog, DialogTitle } from "@mui/material";

import { IMAGES } from "../../../assets/index";
import Spinner from "../../../utils/Spinner";

const ModalLayout = ({
  className,
  open,
  closeModal,
  width = "xs",
  hideHeader = false,
  isLoading,
  title,
  children,
}) => {
  return (
    <Dialog
      className={`${className} modal-dialog`}
      open={open}
      onClose={closeModal}
      maxWidth={width}
      fullWidth
      sx={{
        ".MuiPaper-root": {
          width: "100%",
          margin: "15px",
        },
      }}
    >
      <Box className='modal-dialog-inner hide-scroll'>
        {isLoading && <Spinner />}
        {!hideHeader && (
          <div className='modal-header'>
            <div className='logo'>
              <img src={IMAGES.logo} alt='FERN Logo' />
            </div>
            <DialogTitle className='modal-title'>{title}</DialogTitle>
          </div>
        )}
        {children}
      </Box>
    </Dialog>
  );
};

export default ModalLayout;
