import axios from "axios";
import Axios from "../../config/Axios";

import { encrypt } from "../../utils/security";
import { generateRequestId, generateHeader } from "../../utils/helperFunctions";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const userLogout = async (token, refreshToken) => {
  const payload = {
    token: token,
    refreshToken: refreshToken,
  };
  try {
    const response = await Axios.post("/auth/logout", payload);
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const staffLogin = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/staff/login", payload);
    const data = await response?.data?.data;
    const userData = { isLoggedIn: true, data: { ...data } };

    localStorage.setItem("PGP", encrypt(JSON.stringify(userData)));

    return userData;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const merchantLogin = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/auth/login", payload);
    const data = await response?.data?.data;
    const userData = { isLoggedIn: true, data: { ...data } };

    localStorage.setItem("PGP", encrypt(JSON.stringify(userData)));

    return userData;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const merchantSendResetMail = async (email) => {
  try {
    const response = await Axios(`/auth/forget-password?emailAddress=${email}`);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const merchantResetPassword = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.put("/auth/reset-password", payload);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const changeDefaultPassword = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.put("/auth/change-default-password", payload);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const changePassword = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.put("/auth/change-password", payload);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const refreshAuthToken = async (token, refreshToken) => {
  const payload = { token, refreshToken, requestId: generateRequestId() };

  const configs = {
    headers: {
      ...generateHeader(),
    },
  };

  try {
    const response = await axios.post(baseUrl + "/api/auth/refresh-token", payload, configs);
    const data = await response?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};
