import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { redirect } from "react-router-dom";

import { userAuthActions } from "../reducers/userAuth.reducer";
import { stopSignalR } from "../../App";

const useRedirect = () => {
  const dispatch = useDispatch();

  const redirectHandler = useCallback(async () => {
    localStorage.clear();
    const res = dispatch(userAuthActions.logOut());
    if (res) {
      stopSignalR();
      redirect("/login");
    }
  }, [dispatch]);

  return redirectHandler;
};

export default useRedirect;
