const OnBoarding = () => {
  const formLink = process.env.REACT_APP_INTEREST_INDICATION_FORM;
  return (
    <>
      <h1 className='section-header'>On-Boarding</h1>
      <p>Please fill the form below to confirm your eligibility for this service and get started</p>
      <a
        href={formLink}
        target='_blank'
        rel='noreferrer'
        className='text-purple font-medium underline'
      >
        Eligibility Form
      </a>
    </>
  );
};

export default OnBoarding;
