import { useSelector } from "react-redux";
import MerchantGeneral from "./merchant/General";
import AdminGeneral from "./staff/General";

const General = () => {
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const isMerchant = userType?.toLowerCase()?.includes("merchant");

  return <>{isMerchant ? <MerchantGeneral /> : <AdminGeneral />}</>;
};

export default General;
