import { Box, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../layouts/tables/TableLayout";

const CustomTable = ({ headers, children }) => {
  return (
    <Box className="custom-table-container hide-scroll" sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead sx={{ background: "#F2EEF7" }}>
          <TableRow>
            {headers
              ?.filter((item) => item !== null)
              ?.map((item, index) => (
                <StyledTableCell key={index}>{item}</StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </Box>
  );
};

export default CustomTable;
