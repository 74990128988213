//images import
import logo from "./images/fern.png";
import fcmb from "./images/FCMB Logo.png";
import ladyInRed from "./images/image1.png";
import MCPay from "./images/image2.png";
import DashboardLogo from "./images/dashboard-logo.svg";
import profile from "./images/image3.png";
import excel from "./images/excel.png";
import pdf from "./images/pdf.png";
import url from "./images/url.png";
import keys from "./images/keys.PNG";

//icons import
import speed from "./icons/speed.svg";
import secure from "./icons/secure.svg";
import seamless from "./icons/seamless.svg";
import invoice from "./icons/Invoice.svg";
import report from "./icons/Report.svg";
import payment from "./icons/PaymentOption.svg";
import design from "./icons/design.svg";
import mailFill from "./icons/mail-fill.svg";
import mailOutlined from "./icons/mail-outlined.svg";
import phone from "./icons/phone.svg";
import twitter from "./icons/twitter.svg";
import facebook from "./icons/facebook.svg";
import instagram from "./icons/instagram.svg";
import angleDown from "./icons/angle-down.svg";
import naira from "./icons/naira.svg";
import moreActions from "./icons/more-actions.svg";
import checkIcon from "./icons/checkIcon.svg";
import threeDots from "./icons/three-dots.svg";
import bin from "./icons/bin.svg";
import noData from "./icons/noData.svg";
import { ReactComponent as LogOutIcon } from "./icons/log out.svg";
import { ReactComponent as NotificationIcon } from "./icons/notification-outline.svg";
import { ReactComponent as OverviewIcon } from "./icons/overview.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { ReactComponent as IncomeIcon } from "./icons/income.svg";
import { ReactComponent as CustomersIcon } from "./icons/customers.svg";
import { ReactComponent as TransactionsIcon } from "./icons/transactions.svg";
import { ReactComponent as ReportIcon } from "./icons/reports.svg";
import { ReactComponent as DisputeIcon } from "./icons/dispute.svg";
import { ReactComponent as MobileMenuIcon } from "./icons/hamburger-menu.svg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

// demo store product imports
import nike from "./images/nike.jpg";
import watch from "./images/watch.jpg";
import headphones from "./images/headphones.jpg";
import camera from "./images/camera.jpg";
import backpack from "./images/backpack.jpg";
import drink from "./images/drink.jpg";
import glasses from "./images/glasses.jpg";
import socks from "./images/socks.jpg";

export const IMAGES = {
  logo,
  ladyInRed,
  MCPay,
  DashboardLogo,
  profile,
  pdf,
  excel,
  url,
  keys,
  fcmb,
};

export const ICONS = {
  speed,
  seamless,
  secure,
  invoice,
  report,
  payment,
  design,
  mailFill,
  mailOutlined,
  phone,
  twitter,
  facebook,
  instagram,
  CustomersIcon,
  NotificationIcon,
  OverviewIcon,
  SettingsIcon,
  TransactionsIcon,
  angleDown,
  IncomeIcon,
  ReportIcon,
  LogOutIcon,
  MobileMenuIcon,
  naira,
  moreActions,
  checkIcon,
  threeDots,
  bin,
  noData,
  ManageAccountsIcon,
  DisputeIcon,
};

export const PRODUCTS = {
  nike,
  watch,
  camera,
  drink,
  socks,
  headphones,
  glasses,
  backpack,
};
