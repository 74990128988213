import SummaryCard from "./SummaryCard";

const SummarySection = ({ summary, isLoading }) => {
  return (
    <div className="investment-summary-container grid md:grid-cols-2 xl:grid-cols-4 gap-3 md:gap-5 xl:gap-3">
      <SummaryCard
        isLoading={isLoading}
        color="#5C068C"
        title="All Disputes"
        count={summary?.totalOpenCases + summary?.totalActiveCases + summary?.totalClosedCases}
      />
      <SummaryCard
        isLoading={isLoading}
        color="#1C2092"
        title="Open Cases"
        count={summary?.totalOpenCases}
      />
      <SummaryCard
        isLoading={isLoading}
        color="#09B47C"
        title="Active Cases"
        count={summary?.totalActiveCases}
      />
      <SummaryCard
        isLoading={isLoading}
        color="#7F1D1D"
        title="Closed Cases"
        count={summary?.totalClosedCases}
      />
    </div>
  );
};

export default SummarySection;
