export const emailStructure =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

export const urlStructure =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const secureUrl = /^https:\/\//;

export const phoneNumberStructure = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const passwordStructure = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;

export const numberOnly = /^[0-9]*$/;

export const FCMBEmailStructure = /^[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+@fcmb.com$/i;

export const categories = [
  { value: 8299, label: "Schools and Educational" },
  { value: 7011, label: "Lodging - Hotels, Motels, Resorts" },
  { value: 6533, label: "Payment Transaction - Merchant" },
  { value: 4511, label: "Airlines. Air Carriers" },
  { value: 5399, label: "MIscellaneous General Merchandise stores" },
];

export const paymentChannel = {
  0: "Bank",
  1: "Card",
  2: "PayLater",
};

export const paymentStatus = {
  0: "Initiated",
  1: "Success",
  2: "Pending",
  3: "Failed",
};

export const disputeStatus = {
  0: "Open",
  1: "Active",
  2: "Closed",
};

export const availableTime = [
  { text: "12:00 am", value: "00:00" },
  { text: "01:00 am", value: "01:00" },
  { text: "02:00 am", value: "02:00" },
  { text: "03:00 am", value: "03:00" },
  { text: "04:00 am", value: "04:00" },
  { text: "05:00 am", value: "05:00" },
  { text: "06:00 am", value: "06:00" },
  { text: "07:00 am", value: "07:00" },
  { text: "08:00 am", value: "08:00" },
  { text: "09:00 am", value: "09:00" },
  { text: "10:00 am", value: "10:00" },
  { text: "11:00 am", value: "11:00" },
  { text: "12:00 pm", value: "12:00" },
  { text: "01:00 pm", value: "13:00" },
  { text: "02:00 pm", value: "14:00" },
  { text: "03:00 pm", value: "15:00" },
  { text: "04:00 pm", value: "16:00" },
  { text: "05:00 pm", value: "17:00" },
  { text: "06:00 pm", value: "18:00" },
  { text: "07:00 pm", value: "19:00" },
  { text: "08:00 pm", value: "20:00" },
  { text: "09:00 pm", value: "21:00" },
  { text: "10:00 pm", value: "22:00" },
  { text: "11:00 pm", value: "23:00" },
];
export const roleOptions = [
  {
    value: "All",
    text: "All",
  },
  {
    value: "SuperAdmin",
    text: "SuperAdmin",
  },
  {
    value: "Maker",
    text: "Maker",
  },
  {
    value: "Checker",
    text: "Checker",
  },
];