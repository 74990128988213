import { useEffect, useState } from "react";
import { Button } from "@mui/material";

import { roleOptions } from "../../../utils/constants";
import NoTableData from "../../../components/tables/NoTableData";
import StaffTable from "../../../components/tables/admin/StaffTable";
import useExemption from "../../../services/hooks/lockout-exemption";
import InputSearch from "../../../components/search inputs/InputSearch";
import SelectSearch from "../../../components/search inputs/SelectSearch";
import CustomPagination from "../../../components/tables/CustomPagination";
import TableLayout, { scrollToTableTop } from "../../../layouts/tables/TableLayout";
import ExemptUserModal from "../../../components/modals/table modals/admin/ExemptUserModal";

const ExemptedUsers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const {
    isFetching,
    data,
    fetchExemptedUsers,
    isRemoving,
    isAdding,
    removeStaffUser,
    addStaffUser,
  } = useExemption();

  const searchParamsHandler = (prop) => (e) => {
    setSearchParams({
      ...searchParams,
      [prop]: e.target.value,
    });
  };

  const searchHandler = () => {
    if (searchParams?.name?.length < 1) {
      delete searchParams.name;
    }
    fetchExemptedUsers(searchParams);
  };

  const deleteUserHandler = async (id) => {
    await removeStaffUser(id);
    fetchExemptedUsers(searchParams, data.page);
  };

  const paginationHandler = (page) => {
    scrollToTableTop();
    fetchExemptedUsers(searchParams, page);
  };

  const addUserHandler = async (payload) => {
    await addStaffUser(payload);
    setIsOpen(false);
    fetchExemptedUsers();
  };

  useEffect(() => {
    fetchExemptedUsers();
  }, [fetchExemptedUsers]);

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-1.5 md:gap-3 w-full mb-5">
          <SelectSearch
            id="roleRange"
            className="selectBox w-full md:w-[150px]"
            label="Role"
            value={searchParams?.role}
            selectItems={roleOptions}
            onSelect={searchParamsHandler("role")}
          />
          <div className="flex flex-col w-full md:w-auto md:flex-row items-center gap-1.5 md:gap-3">
            <InputSearch
              className="inputSearch w-full md:w-[300px]"
              placeholder="Search by name..."
              onChange={searchParamsHandler("name")}
            />
            <div className="action-btn flex w-full md:w-auto items-center gap-1.5 md:gap-3">
              <Button
                className="w-1/2 md:w-[120px] h-10"
                variant="outlined"
                disableElevation
                onClick={searchHandler}
                disabled={isFetching}
              >
                Search
              </Button>
              <Button
                className="w-1/2 md:w-[150px] h-10"
                variant="contained"
                disableElevation
                onClick={() => setIsOpen(true)}
                disabled={isFetching}
              >
                Exempt User
              </Button>
            </div>
          </div>
        </div>
        <TableLayout isLoading={isFetching}>
          {data.entries?.length > 0 && (
            <StaffTable
              isRemove
              title="Exempted Users"
              tableRows={data.entries}
              onDelete={deleteUserHandler}
              isLoading={isRemoving}
            />
          )}
          {data.entries?.length < 1 && (
            <NoTableData link="Exempt User" onClick={() => setIsOpen(true)} />
          )}
          {data.entries?.length > 0 && data.totalPages > 1 && (
            <CustomPagination
              paginationHandler={paginationHandler}
              totalPages={data.totalPages}
              page={data.page}
            />
          )}
        </TableLayout>
      </div>
      {isOpen && (
        <ExemptUserModal
          isLoading={isAdding}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onComplete={addUserHandler}
          exemptedUsers={data.entries}
        />
      )}
    </>
  );
};

export default ExemptedUsers;
