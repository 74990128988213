import { ICONS } from "../../assets";

export const features = [
  {
    icon: ICONS.seamless,
    title: "Seamless",
    subtitle:
      "Welcome to the future of payment processing - where simplicity and convenience merge to create the ultimate seamless experience for merchants! Our payment gateway ensures a frictionless transaction process that your customers will love. With a sleek and intuitive user interface, your team can easily navigate through the payment flow, effortlessly accepting various payment methods.",
  },
  {
    icon: ICONS.secure,
    title: "Secure",
    subtitle:
      "Your transactions are safeguarded by our top-tier security protocols! At Fern, security is our unwavering priority. We deploy state-of-the-art encryption techniques, ensuring that sensitive data remains encrypted and protected from prying eyes. Our multi-layered security architecture shields you from potential cyber threats.",
  },
  {
    icon: ICONS.speed,
    title: "Speed",
    subtitle:
      "Time is money, and we value both! Brace yourself for fast payment processing that will leave you astonished. Our high-performance payment gateway is designed to optimize every second of your valuable time. With -fast response times, your customers will complete their transactions swiftly, without a moment's hesitation. ",
  },
];

export const tabInfo = [
  {
    id: 1,
    name: "Multiple Payment Options",
    desc: "FERN payment gateway accepts a multitude of payment options such as debit/credit cards, bank transfers.",
    icon: ICONS.payment,
  },
  {
    id: 2,
    name: "Detailed Reporting",
    desc: "Transactions can be viewed and exported for all payment channels or by individual payment channels",
    icon: ICONS.report,
  },
];
