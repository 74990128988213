import "./index.css";

const LayoutPageContent = (props) => {
  return (
    <div className="layout-page-content relative wrapper py-6 px-4 md:px-5 xl:pl-6 xl:pr-5 hide-scroll content-container overflow-y-auto">
      {props.children}
    </div>
  );
};

export default LayoutPageContent;
