import React from "react";
import { Backdrop, Box, LinearProgress } from "@mui/material";

const Loader = () => {
  const style = {
    position: "absolute",
    zIndex: "20",
    top: "0px",
    width: "100%",
  };
  return (
    <Box className="loader" sx={{ ...style }}>
      <Backdrop open={true} />
      <LinearProgress />
    </Box>
  );
};

export default Loader;
