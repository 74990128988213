import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import CheckerStatus from "./status/CheckerStatus";

const PendingApprovals = () => {
  const userRole = useSelector(
    (state) => state?.userAuth?.data?.role
  )?.toLowerCase();
  return userRole === "checker" ? (
    <CheckerStatus
      status="pending"
      title="Pending Approvals"
      search={true}
      action={true}
      actionsType="default"
    />
  ) : (
    <Navigate to="/not-found" replace />
  );
};

export default PendingApprovals;
