import "./index.css";
import { useState, useCallback, useEffect } from "react";
import { Box, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";

import {
  getRestChannelsState,
  updateChannels,
} from "../../../../../services/actions/merchant.actions";
import { alertError } from "../../../../../utils/helperFunctions";
import Spinner from "../../../../../utils/Spinner";

const Channels = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentChannels, setPaymentChannels] = useState({
    bank: false,
    card: false,
    payLater: false,
  });

  const fetchChannels = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getRestChannelsState();
      setPaymentChannels(response);
    } catch (error) {
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const handleChange = async (event) => {
    const { name, checked } = event.target;
    setIsLoading(true);
    try {
      const response = await updateChannels(name, checked ? 1 : 0);
      if (response) {
        setPaymentChannels((p) => ({ ...p, [name]: checked }));
      }
    } catch (error) {
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="channels-settings-container">
      <div className="settings-form">
        <Stack gap="15px" className="channels-group">
          <Stack gap="5px">
            <h1 className="title">Payment Options</h1>
            <p className="sub-title">Activate and deactivate any of our payment channels</p>
          </Stack>
          <FormGroup className="toggles relative">
            {isLoading && <Spinner />}
            <FormControlLabel
              className="toggle-control"
              control={
                <Switch checked={paymentChannels.bank} onChange={handleChange} name="bank" />
              }
              label="Bank"
            />

            <FormControlLabel
              className="toggle-control"
              control={
                <Switch checked={paymentChannels.card} onChange={handleChange} name="card" />
              }
              label="Card"
            />

            <FormControlLabel
              className="toggle-control"
              control={
                <Switch
                  checked={paymentChannels.payLater}
                  onChange={handleChange}
                  name="payLater"
                />
              }
              label="Pay Later"
            />
          </FormGroup>
        </Stack>
      </div>
    </Box>
  );
};

export default Channels;
