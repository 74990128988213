const GettingStarted = () => {
  return (
    <>
      <h1 className="section-header">Getting Started</h1>
      <p>
        Welcome to the FERN Payment Gateway Developer Documentation where you’ll
        learn how to build amazing payment experiences with the FERN Payment
        Gateway API.
      </p>
    </>
  );
};

export default GettingStarted;
