import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

import ProductCard from "./ProductCard";
import OrderSummary from "./OrderSummary";
import Confirmation from "./Confirmation";
import CustomerDetails from "./CustomerDetails";
import Header from "../../components/common/header/Header";
import Footer from "../../components/common/footer/Footer";
import Inner from "../../layouts/inner landing page/Inner";
import { triggerGateway } from "../../utils/helperFunctions";
import CustomTable from "../../components/tables/CustomTable";

import { order, products, tableHeaders, user, vat } from "./data";

const Store = () => {
  const navigate = useNavigate();

  const [cart, setCart] = useState({});
  const [coupon, setCoupon] = useState(false);
  const [summary, setSummary] = useState(order);
  const [details, setDetails] = useState(user);

  const calcTotal = (id, price, quan, name) => {
    setCart((p) => ({
      ...p,
      [id]: { price, name, quantity: quan, total: quan * price },
    }));
  };

  const removeItem = (id) => {
    let cartCopy = cart;
    delete cartCopy[id];
    setCart((p) => ({ ...cartCopy }));
  };

  function sum(obj) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key]?.total || 0),
      0
    );
  }

  const orderSummaryHandler = useCallback(() => {
    let calPrice = coupon ? sum(cart) * (50 / 100) : sum(cart);
    let calVAT = (calPrice * vat) / 100;
    let calDelivery = calPrice < 500 ? 0 : calPrice > 50000 ? 1000 : 2000;
    let calTotal = calPrice + calVAT + calDelivery;

    setSummary({
      subTotal: calPrice,
      vat: calVAT,
      delivery: calDelivery,
      total: calTotal,
    });
  }, [cart, coupon]);

  const couponHandler = () => {
    orderSummaryHandler(cart, !coupon);
    setCoupon((p) => !p);
  };

  const customerHandler = (e) => {
    const { value, name } = e.target;
    setDetails((p) => ({ ...p, [name]: value }));
  };

  const onPayHandler = (e) => {
    e.preventDefault();

    if (summary.total === 0) {
      return;
    }

    let email = details.email;
    let customerName = details.name;
    let amount = summary.total;

    localStorage.setItem(
      "order",
      JSON.stringify({ items: Object.values(cart), total: amount })
    );

    triggerGateway(navigate, email, amount, customerName);
  };

  useEffect(() => {
    orderSummaryHandler();
  }, [orderSummaryHandler]);

  return (
    <Box>
      <Confirmation />
      <Header />
      <Box className="generic-banner lg:h-[350px] h-[200px]">
        <h1 className="font-[700] lg:text-[48px] md:text-[40px] text-[30px] text-center text-black opacity-90">
          Demo Store
        </h1>
      </Box>
      <Inner>
        <Box
          display="grid"
          gridTemplateColumns={{ lg: "3fr 2fr" }}
          gap="50px"
          my={{ xs: "50px", md: "100px " }}
        >
          <Box
            sx={{ p: { xs: "20px 0px", sm: "0px 0px 0px" } }}
            className="border border-solid rounded-[10px] border-gray overflow-hidden"
            height="fit-content"
          >
            <CustomTable headers={tableHeaders}>
              {products.map((product) => (
                <ProductCard
                  key={product.id}
                  image={product.image}
                  name={product.name}
                  price={product.price}
                  sku={product.sku}
                  id={product.id}
                  updateTotal={calcTotal}
                  onRemove={removeItem}
                />
              ))}
            </CustomTable>
          </Box>
          <Box
            sx={{ p: { xs: "30px 20px 50px", sm: "50px 50px" } }}
            className="border border-solid rounded-[10px] border-gray"
          >
            <form className="flex flex-col gap-[40px]" onSubmit={onPayHandler}>
              <CustomerDetails details={details} onChange={customerHandler} />
              <OrderSummary
                summary={summary}
                coupon={coupon}
                toggle={couponHandler}
              />
              <Button
                variant="contained"
                size="large"
                disabled={summary.total < 1}
                sx={{
                  height: "50px",
                  textTransform: "uppercase !important",
                  fontSize: "16px",
                  fontWeight: 600,
                  letterSpacing: "2px",
                }}
                type="submit"
              >
                Complete Purchase
              </Button>
            </form>
          </Box>
        </Box>
      </Inner>
      <Footer />
    </Box>
  );
};

export default Store;
