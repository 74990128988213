import "./index.css";
import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";

import { userAuthActions } from "../../../../../services/reducers/userAuth.reducer";
import { updateMerchantSchema } from "../../../../../utils/formikFormValidators";
import { updateProfile } from "../../../../../services/actions/merchant.actions";
import { alertError, alertSuccess } from "../../../../../utils/helperFunctions";
import Spinner from "../../../../../utils/Spinner";

const MerchantGeneral = () => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state?.userAuth?.data?.role)?.toLowerCase();
  const website = useSelector((state) => state?.userAuth?.data?.website)?.toLowerCase();
  const callbackUrl = useSelector((state) => state?.userAuth?.data?.callbackUrl)?.toLowerCase();
  const displayName = useSelector((state) => state?.userAuth?.data?.displayName)?.toLowerCase();
  const merchantCode = useSelector((state) => state?.userAuth?.data?.merchantCode);
  const description = useSelector((state) => state?.userAuth?.data?.description);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      merchantCode: merchantCode,
      displayName: displayName,
      website: website || "https://",
      callbackUrl: callbackUrl || "https://",
      description: description || "",
    },
    validationSchema: updateMerchantSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const submitHandler = async (prop) => {
    setIsLoading(true);
    let payload = prop;
    try {
      const response = await updateProfile(payload);
      dispatch(userAuthActions.update(response));

      alertSuccess("Profile updated successfully");
      setIsEdit(false);
    } catch (error) {
      alertError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="general-settings-container">
      <Stack direction="row" justifyContent="space-between">
        <h1 className="title">General Settings</h1>
        {role === "merchantadmin" &&
          (!isEdit ? (
            <p
              className="text-sm text-purple cursor-pointer font-[500]"
              onClick={() => setIsEdit(!isEdit)}
            >
              Edit Profile
            </p>
          ) : (
            <p
              className="text-sm text-red cursor-pointer font-[500]"
              onClick={() => setIsEdit(!isEdit)}
            >
              Cancel
            </p>
          ))}
      </Stack>
      <form className="settings-form" onSubmit={formik.handleSubmit}>
        <div className="form-fields">
          <TextField
            name="displayName"
            label="Display Name"
            id="displayName"
            className="input-section"
            fullWidth
            required
            disabled={!isEdit}
            inputProps={{
              sx: { textTransform: "capitalize" },
            }}
            value={formik.values.displayName}
            onChange={formik.handleChange}
            error={formik.touched.displayName && Boolean(formik.errors.displayName)}
            helperText={formik.touched.displayName && formik.errors.displayName}
          />
          <TextField
            name="description"
            label="Description"
            id="description"
            className="textArea-section"
            fullWidth
            multiline
            rows={4}
            disabled={!isEdit}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            name="website"
            label="Website"
            id="website"
            className="input-section"
            fullWidth
            placeholder="https://"
            disabled={!isEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon />
                </InputAdornment>
              ),
            }}
            value={formik.values.website}
            onChange={formik.handleChange}
            error={formik.touched.website && Boolean(formik.errors.website)}
            helperText={formik.touched.website && formik.errors.website}
          />
          <TextField
            name="callbackUrl"
            label="Callback URL"
            id="callbackUrl"
            className="input-section"
            fullWidth
            placeholder="https://"
            disabled={!isEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon />
                </InputAdornment>
              ),
            }}
            value={formik.values.callbackUrl}
            onChange={formik.handleChange}
            error={formik.touched.callbackUrl && Boolean(formik.errors.callbackUrl)}
            helperText={formik.touched.callbackUrl && formik.errors.callbackUrl}
          />
        </div>
        {isEdit && (
          <Button
            type="submit"
            className="save-changes-btn"
            color="primary"
            variant="contained"
            disableElevation
          >
            {isLoading && <Spinner color="primary" size={24} />}
            Update Profile
          </Button>
        )}
      </form>
    </Box>
  );
};

export default MerchantGeneral;
