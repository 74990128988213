import { ICONS } from "../assets";

const {
  OverviewIcon,
  TransactionsIcon,
  CustomersIcon,
  ReportIcon,
  SettingsIcon,
  ManageAccountsIcon,
  DisputeIcon,
  IncomeIcon,
} = ICONS;

export const AppRoutes = {
  home: "/",
  overview: "/overview",
  overviewTab: (tab) => `/overview/${tab}`,
  firstTimeMerchant: "/change-password",
  staffUsers: "/manage-staff",
  lockout: "/system-lockout",
  transactions: "/transactions",
  transaction: (id) => `/transactions/${id}`,
  income: "/income",
  merchants: "/merchants",
  merchant: (id, type) => `/merchants/${type}/details/${id}`,
  reports: "/reports",
  disputes: "/disputes",
  disputeTab: (type) => `/disputes/${type}`,
  dispute: (id, type) => `/disputes/${type}/details/${id}`,
  settings: "/settings",
};

export const dashboardNav = [
  {
    path: AppRoutes.overview,
    icon: <OverviewIcon />,
    role: ["checker", "maker", "merchantadmin", "merchantuser"],
    type: ["staff", "merchant"],
    name: "Overview",
  },
  {
    path: AppRoutes.transactions,
    icon: <TransactionsIcon />,
    role: ["checker", "maker", "merchantadmin", "merchantuser"],
    type: ["staff", "merchant"],
    name: "Transactions",
  },
  {
    path: AppRoutes.income,
    icon: <IncomeIcon />,
    role: ["merchantadmin", "merchantuser"],
    type: ["merchant"],
    name: "Income",
  },
  {
    path: AppRoutes.merchants,
    icon: <CustomersIcon />,
    role: ["checker", "maker"],
    type: ["staff"],
    name: "Merchants",
  },
  {
    path: AppRoutes.reports,
    icon: <ReportIcon />,
    role: ["checker", "maker"],
    type: ["staff"],
    name: "Reports",
  },
  {
    path: AppRoutes.disputes,
    icon: <DisputeIcon />,
    role: ["maker", "checker", "merchantadmin", "merchantuser"],
    type: ["staff", "merchant"],
    name: "Disputes",
    dropdown: [
      { name: "Open", path: AppRoutes.disputeTab("open") },
      { name: "Active", path: AppRoutes.disputeTab("active") },
      { name: "Closed", path: AppRoutes.disputeTab("closed") },
    ],
  },
  {
    path: AppRoutes.settings,
    icon: <SettingsIcon />,
    role: ["maker", "checker", "merchantadmin", "merchantuser"],
    type: ["staff", "merchant"],
    name: "Settings",
  },
  {
    path: AppRoutes.staffUsers,
    icon: <ManageAccountsIcon />,
    role: ["superadmin"],
    type: ["staff"],
    name: "Manage Users",
  },
  {
    path: AppRoutes.lockout,
    icon: <SettingsIcon />,
    role: ["superadmin"],
    type: ["staff"],
    name: "System Lockout",
  },
];
