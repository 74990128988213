import "./index.css";
import { Box } from "@mui/material";

import TableLayout from "../../tables/TableLayout";
import Switch from "../../../components/switch/Switch";

const TransactionDetailsLayout = ({
  className,
  isLoading,
  title,
  switcher,
  status,
  isToggled,
  onToggle,
  children,
}) => {
  return (
    <TableLayout sx={{ padding: "15px 15px 40px" }} className={className} isLoading={isLoading}>
      <Box className="transaction-details-container">
        <Box className="section-heading">
          <div className="flex justify-between align-middle">
            <h2 className="title">{title}</h2>
            {switcher && status === "approved" && (
              <div className="flex items-center text-sm font-medium gap-[10px] mb-[8px]">
                Deactivate
                <Switch isToggled={isToggled} onToggle={onToggle} />
                Activate
              </div>
            )}
          </div>
          <hr></hr>
        </Box>
        <div className="section-body">{children}</div>
      </Box>
    </TableLayout>
  );
};

export default TransactionDetailsLayout;
