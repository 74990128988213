import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

import useDispute from "../../../services/hooks/useDispute";
import ConfirmAction from "../confirmation/ConfirmAction";
import { AppRoutes } from "../../../routes/AppRoutes";
import AppUploader from "../../shared/AppUploader";
import Spinner from "../../../utils/Spinner";

const DisputeResponseModal = (props) => {
  const { open, onClose, disputeId } = props;

  const commentRef = useRef();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [action, setAction] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [errorComment, setErrorComment] = useState(false);
  const { updateStatus, isUpdating, addFile, isFiling } = useDispute();

  const handleAction = async (action) => {
    setErrorFile(false);
    setErrorComment(false);
    const comment = commentRef.current.value;

    // checks for required data to decline dispute
    if (action === "decline") {
      if (comment.length === 0) {
        setErrorComment(true);
      }
      if (!file) {
        setErrorFile(true);
      }
      if (comment.length === 0 || !file) {
        return;
      }
    }

    setAction(action);
    setIsOpen(true);
  };

  const handleConfirmation = async () => {
    const comment = commentRef.current.value;
    const payload = { disputeId, newStatus: "active", comment };

    // upload file to dispute
    if (file) {
      const formData = new FormData();
      formData.append("files", file);
      const res = await addFile(disputeId, formData);
      if (res) {
        payload.fileId = res?.[0]?.id;
      }
    }

    // update dispute status to reflect merchant response
    const res = await updateStatus(disputeId, payload);
    if (res) {
      onClose();
      setFile(null);
      navigate(AppRoutes.dispute(disputeId, "active"));
    }

    setIsOpen(false);
  };

  return (
    <>
      <Dialog className="logout-dialog" open={open} onClose={onClose} fullWidth maxWidth="xs">
        {(isFiling || isUpdating) && <Spinner />}
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: "18px" }}
          id="customized-dialog-title"
          className="!opacity-90"
        >
          Dispute response
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="flex flex-col w-full gap-4">
            <TextField
              fullWidth
              multiline
              rows={4}
              onInput={() => {
                if (commentRef.current.value.length > 150) {
                  commentRef.current.value = commentRef.current.value.slice(0, 150);
                }
              }}
              inputRef={commentRef}
              variant="outlined"
              placeholder="comment..."
              onChange={() => setErrorComment(false)}
              id="filled-multiline-comment"
              sx={{
                ".MuiInputBase-root": { pt: "12px", borderRadius: "3px" },
              }}
              error={errorComment}
              helperText={
                errorComment ? "comment is required to decline dispute" : "150 characters max"
              }
            />
            <div className="flex flex-col gap-1 w-full">
              <div className="w-full h-20">
                <AppUploader
                  isError={errorFile}
                  onSelect={(file) => {
                    setFile(file);
                    setErrorFile(false);
                  }}
                />
              </div>
              {errorFile && (
                <p className="text-xs text-alert">File is required to decline dispute</p>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ minWidth: "150px", height: "45px" }}
            variant="outlined"
            disableElevation
            onClick={() => handleAction("decline")}
          >
            Decline
          </Button>
          <Button
            sx={{ minWidth: "150px", height: "45px" }}
            variant="contained"
            disableElevation
            onClick={() => handleAction("accept")}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      {action && (
        <ConfirmAction
          open={isOpen}
          focusText={`Are you sure you want to ${action} this dispute?`}
          description="This action can not be reversed"
          confirmAction={handleConfirmation}
          closeDialog={() => setIsOpen(false)}
          isLoading={isFiling || isUpdating}
          type={action === "decline" ? "delete" : ""}
        />
      )}
    </>
  );
};

export default DisputeResponseModal;
