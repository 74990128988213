import { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, Stack } from "@mui/material";
import moment from "moment";

import { numberWithCommas } from "../../utils/helperFunctions";
import { IMAGES } from "../../assets";

const Confirmation = () => {
  const [popup, setPopup] = useState(false);
  const [orders, setOrders] = useState({});

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const status = urlParams.get("status");
  const transactionId = urlParams.get("transactionRef");

  const isSuccess = status?.toLowerCase()?.includes("success");

  const closeModal = () => {
    setPopup(false);
  };

  const quantity = orders?.items?.reduce(
    (accumulator, order) => accumulator + order.quantity,
    0
  );

  const getItems = useCallback(() => {
    const storage = localStorage.getItem("order");
    if (storage) {
      setOrders(JSON.parse(storage));
    }
  }, []);

  useEffect(() => {
    if (transactionId) {
      getItems();
      setPopup(true);
    }
  }, [getItems, transactionId]);

  return (
    <Dialog
      className="logout-dialog"
      open={popup}
      onClose={closeModal}
      fullWidth
      sx={{
        ".MuiPaper-root": { margin: "10px !important", width: "100%" },
      }}
    >
      <Box p={{ xs: "30px 10px", sm: "30px 50px" }}>
        <code className="flex flex-col gap-3 w-full items-center">
          <div className="logo w-[70px] mb-3">
            <img src={IMAGES.logo} alt="FERN Logo" />
          </div>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            pb="5px"
            gap="10px"
            className="border-b border-dashed"
          >
            <p>INVOICE: {transactionId}</p>
            <p>{moment().format("DD-MMM-YY : h:mm a")}</p>
          </Stack>
          <Stack width="95%" gap="10px">
            {orders?.items?.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <p>
                  {parseFloat(item.quantity).toFixed(2)} X {item.name}
                </p>
                <p>{parseFloat(item.total).toFixed(2)}</p>
              </Stack>
            ))}
            <Stack direction="row" gap="10px" alignItems="center">
              <p>{parseFloat(quantity).toFixed(2)}</p>
              <Box className="flex-grow border-b border-dashed" />
            </Stack>
          </Stack>
          <Stack
            width="100%"
            direction="row"
            gap="30px"
            justifyContent="flex-end"
          >
            <p>Total NGN</p>
            <p>{numberWithCommas(parseFloat(orders.total).toFixed(2))}</p>
          </Stack>
          <Stack width="100%" direction="row" justifyContent="flex-end">
            <p className="text-sm">VAT and Delivery fee included</p>
          </Stack>
          <div className="md:w-[90%] mt-5 flex flex-col gap-5 justify-center">
            <h1 className="text-center text-grey text-lg font-bold uppercase tracking-[1px]">
              ***{" "}
              <span
                className={`inline-block ${
                  isSuccess ? "text-green" : "text-failed"
                }`}
              >
                Transaction {isSuccess ? "Successful" : "Failed"}
              </span>{" "}
              ***
            </h1>
            <p className="text-center leading-[20px]">
              This is a demo shopping cart for the sole purpose of testing our{" "}
              <b>Payment Gateway System</b>
              <br />
              Items listed here are not for sale
            </p>
          </div>

          <Button
            variant="contained"
            size="large"
            disableElevation
            color="secondary"
            sx={{
              height: "45px",
              textTransform: "uppercase !important",
              fontSize: "12px",
              fontWeight: 600,
              letterSpacing: "2px",
              marginTop: "20px",
            }}
            onClick={closeModal}
          >
            Close Transaction Invoice
          </Button>
        </code>
      </Box>
    </Dialog>
  );
};

export default Confirmation;
