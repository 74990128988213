import "./index.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";

import Loader from "../../utils/Loader";
import FormContainer from "./formContainer";
import { alertError, alertSuccess } from "../../utils/helperFunctions";
import { merchantSendResetMail } from "../../services/actions/authentication.actions";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [resetComplete, setResetComplete] = useState(false);

  const onChangeHandler = (e) => {
    setEmailAddress(e.target.value);
  };

  const resetHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const result = await merchantSendResetMail(emailAddress);
      if (result) {
        alertSuccess("Reset email sent successfully");
        setResetComplete(true);
      }
    } catch (error) {
      setResetComplete(false);
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="reset-container">
      {isLoading && <Loader />}
      <FormContainer sub="Reset your password" isLoading={isLoading}>
        {!resetComplete && (
          <form onSubmit={resetHandler} className="reset-form">
            <div className="form-fields">
              <TextField
                name="emailAddress"
                label="Email"
                id="emailAddress"
                className="input-section"
                type="email"
                fullWidth
                required
                value={emailAddress}
                onChange={onChangeHandler}
              />
            </div>
            <div className="action-buttons">
              <Button
                variant="contained"
                disableElevation
                sx={{
                  height: "55px",
                  fontSize: "16px",
                }}
                className="gradient"
                type="submit"
              >
                Reset Password
              </Button>
              <Link to={"/login"} className="forgot-link">
                Back to login
              </Link>
            </div>
          </form>
        )}
        {resetComplete && (
          <div className="confirmation">
            <p className="message">
              We just sent a recovery email to{" "}
              <span className="text-purple font-medium">{emailAddress?.toLowerCase()}</span>
              <br />
              Please click the link to create a new password.
            </p>
            <div className="action-buttons">
              <Button
                variant="contained"
                sx={{
                  height: "55px",
                  fontSize: "16px",
                }}
                disableElevation
                className="gradient"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Sign In
              </Button>
            </div>
          </div>
        )}
      </FormContainer>
    </Box>
  );
};

export default ForgotPassword;
