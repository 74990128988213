import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import ModalLayout from "../ModalLayout";
import { roleOptions } from "../../../../utils/constants";
import InputSearch from "../../../search inputs/InputSearch";
import SelectSearch from "../../../search inputs/SelectSearch";
import CustomPagination from "../../../tables/CustomPagination";
import useFetchStaff from "../../../../services/hooks/fetch-staff-users";
import SystemLockoutTable from "../../../tables/admin/SystemLockoutTable";

const ExemptUserModal = ({ open, onClose, onComplete, exemptedUsers, isLoading }) => {
  const [checkList, setCheckList] = useState(exemptedUsers?.map((a) => a.staffId) || []);
  const {
    isFetching,
    fetchAllStaffUsers,
    data,
    onSearch,
    onSearchInput,
    onPageChange,
    searchParams,
  } = useFetchStaff();

  const handleSubmit = () => {
    if (checkList.length === 0) return;
    onComplete({ staffIds: checkList });
  };

  useEffect(() => {
    fetchAllStaffUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalLayout
      isLoading={isFetching || isLoading}
      className="dispute-modal"
      open={open}
      closeModal={onClose}
      hideHeader
      width="sm"
    >
      <div className="w-full flex flex-col gap-7">
        <div className="text-left">
          <h3 className="text-lg text-black font-medium">Exempt Users</h3>
          <p className="text-black-10 mt-1">
            These are users that have access to the system 24hours
          </p>
        </div>
        <div className="w-full flex flex-col gap-[10px]">
          <div className="w-full flex flex-col md:flex-row gap-1 items-center justify-between">
            <SelectSearch
              id="roleRange"
              className="selectBox w-full md:w-[120px]"
              label="Role"
              value={searchParams?.role || ""}
              selectItems={roleOptions}
              onSelect={onSearchInput("role")}
            />
            <div className="w-full md:w-auto flex flex-col md:flex-row items-center gap-1">
              <InputSearch
                className="inputSearch w-full md:w-[200px]"
                placeholder="Search by name..."
                onChange={onSearchInput("name")}
              />
              <Button
                className="h-10 w-full md:w-[120px]"
                variant="contained"
                disableElevation
                onClick={onSearch}
                disabled={isFetching}
              >
                Search
              </Button>
            </div>
          </div>
          <div className="max-h-[50vh] hide-scroll">
            {data.entries?.length > 0 && (
              <SystemLockoutTable
                exempted={exemptedUsers}
                title="Staff Users"
                tableRows={data.entries}
                selected={checkList}
                setSelected={setCheckList}
              />
            )}
          </div>
          {data.entries?.length > 0 && data.totalPages > 1 && (
            <CustomPagination
              paginationHandler={onPageChange}
              totalPages={data.totalPages}
              page={data.page}
            />
          )}
          <div className="w-full flex justify-end border-t border-gray px-2 pt-2">
            <Button
              type="button"
              className="w-fit px-4 h-10"
              color="primary"
              variant="contained"
              disableElevation
              onClick={handleSubmit}
              disabled={checkList.length === 0}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ExemptUserModal;
