import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

import UploadIcon from "@mui/icons-material/Upload";

import SummarySection from "./summary/SummarySection";
import useDispute from "../../../services/hooks/useDispute";
import TableLayout from "../../../layouts/tables/TableLayout";
import DisputeTable from "../../../components/tables/DisputeTable";
import AppPageHeader from "../../../components/shared/AppPageHeader";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import DisputeModal from "../../../components/modals/table modals/admin/DisputeModal";

const Dispute = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { fetchSummary, summary, isLoadingSummary } = useDispute();
  const { userType } = useSelector((state) => state?.userAuth?.data);

  const isStaff = userType?.toLowerCase() === "staff";

  const handleViewAll = (tab) => {
    navigate(`/disputes/${tab}`);
  };

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  return (
    <DashboardLayout>
      <div className="hide-scroll">
        <AppPageHeader
          title="Disputes"
          secondary={
            isStaff ? (
              <Button variant="outlined" startIcon={<UploadIcon />} onClick={() => setIsOpen(true)}>
                Upload Dispute
              </Button>
            ) : null
          }
        />
        <div className="flex flex-col gap-[50px] mb-[40px] mt-[32px]">
          <SummarySection isLoading={isLoadingSummary} summary={summary} />
          {summary?.recentOpenCases?.length > 0 && (
            <TableLayout isLoading={isLoadingSummary}>
              <DisputeTable
                tableData={summary.recentOpenCases}
                title="Open Dispute Cases"
                onViewAll={() => handleViewAll("open")}
              />
            </TableLayout>
          )}
          {summary?.recentActiveCases?.length > 0 && (
            <TableLayout isLoading={isLoadingSummary}>
              <DisputeTable
                tableData={summary.recentActiveCases}
                title="Active Dispute Cases"
                onViewAll={() => handleViewAll("active")}
              />
            </TableLayout>
          )}
        </div>
      </div>
      {isStaff && (
        <DisputeModal open={isOpen} onClose={() => setIsOpen(false)} reload={fetchSummary} />
      )}
    </DashboardLayout>
  );
};

export default Dispute;
