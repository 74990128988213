import Axios from "../../config/Axios";
import { generateRequestId } from "../../utils/helperFunctions";

export const verifyStaffEmail = async (email) => {
  const payload = email;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/staff/isvalidemail", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const adminCreateStaff = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/staff", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const adminUpdateStaff = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.put("/staff", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const adminDeleteStaff = async (id) => {
  try {
    const response = await Axios.post(`/staff/delete?staffId=${id}`);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const fetchAllStaff = async (params, page = 1) => {
  try {
    const response = await Axios(`/staffs?page=${page}&limit=15`, { params });
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const getGraphData = async (range) => {
  try {
    const response = await Axios(`/staff/dashboard-data?range=${range}`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

// system lockout feature

export const fetchExemptedStaff = async (params, page = 1) => {
  try {
    const response = await Axios(`/staffs/action/GetExemptedStaff?page=${page}&limit=15`, {
      params,
    });
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const setLockOutTime = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/staff/action/SettimeOut", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const fetchLockOutTime = async () => {
  try {
    const response = await Axios("/staff/action/GettimeOut");
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const deleteStaffExemption = async (id) => {
  try {
    const response = await Axios.post(`staff/action/DeleteExemption?staffId=${id}`);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const addStaffExemption = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/staff/action/SetExemption", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};
