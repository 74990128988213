import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  addDisputeComment,
  addDisputeFile,
  createBatchDispute,
  createDispute,
  getAllDisputes,
  getDisputeComments,
  getDisputeSummary,
  getSingleDispute,
  removeDisputeComment,
  removeDisputeFile,
  updateDisputeStatus,
} from "../actions/dispute.actions";
import { alertError, alertSuccess } from "../../utils/helperFunctions";
import { AppRoutes } from "../../routes/AppRoutes";

const useDispute = () => {
  const navigate = useNavigate();
  const [comments, setComments] = useState([]);
  const [summary, setSummary] = useState(null);
  const [allError, setAllError] = useState(false);
  const [allDisputes, setAllDisputes] = useState(null);
  const [singleDispute, setSingleDispute] = useState(null);
  const [isLoadingSummary, setisLoadingSummary] = useState(false);
  const [isLoadingSingle, setisLoadingSingle] = useState(false);
  const [isCommenting, setisCommenting] = useState(false);
  const [isLoadingAll, setisLoadingAll] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [isFetching, setisFetching] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [isFiling, setisFiling] = useState(false);

  const fetchSummary = useCallback(async () => {
    setisLoadingSummary(true);
    try {
      const response = await getDisputeSummary();
      if (response) {
        setSummary(response);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoadingSummary(false);
    }
  }, []);

  const fetchAll = useCallback(async (params) => {
    setisLoadingAll(true);
    setAllError(false);
    try {
      const response = await getAllDisputes(params);
      if (response) {
        setAllDisputes(response);
      }
    } catch (error) {
      setAllError(true);
      alertError(error);
    } finally {
      setisLoadingAll(false);
    }
  }, []);

  const fetchComments = useCallback(async (id) => {
    setisFetching(true);
    try {
      const response = await getDisputeComments(id);
      if (response) {
        setComments(response.comments);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisFetching(false);
    }
  }, []);

  const fetchSingle = useCallback(
    async (id) => {
      setisLoadingSingle(true);
      try {
        const response = await getSingleDispute(id);
        if (response) {
          setSingleDispute(response);
        }
      } catch (error) {
        alertError(error);
        navigate(AppRoutes.disputeTab("open"));
      } finally {
        setisLoadingSingle(false);
      }
    },
    [navigate]
  );

  const uploadNew = useCallback(async (payload) => {
    setisUploading(true);
    try {
      const response = await createDispute(payload);
      if (response) {
        alertSuccess("Dispute created successfully");
        return true;
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisUploading(false);
    }
  }, []);

  const uploadBatch = useCallback(async (payload) => {
    setisUploading(true);
    try {
      const response = await createBatchDispute(payload);
      if (response) {
        alertSuccess("Disputes created successfully");
        return true;
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisUploading(false);
    }
  }, []);

  const updateStatus = useCallback(async (id, payload) => {
    setisUpdating(true);
    try {
      await updateDisputeStatus(id, payload);
      alertSuccess("Dispute status has been updated");
      return true;
    } catch (error) {
      alertError(error);
    } finally {
      setisUpdating(false);
    }
  }, []);

  const addFile = useCallback(async (id, payload) => {
    setisFiling(true);
    try {
      const res = await addDisputeFile(id, payload);
      alertSuccess("File uploaded successfully");
      return res;
    } catch (error) {
      alertError(error);
    } finally {
      setisFiling(false);
    }
  }, []);

  const deleteFile = useCallback(
    async (id, fileId) => {
      setisFiling(true);
      try {
        await removeDisputeFile(id, fileId);
        alertSuccess("File deleted successfully");
        fetchSingle(id);
        return true;
      } catch (error) {
        alertError(error);
      } finally {
        setisFiling(false);
      }
    },
    [fetchSingle]
  );

  const addComment = useCallback(async (id, payload) => {
    setisCommenting(true);
    try {
      await addDisputeComment(id, payload);
      alertSuccess("Comment posted successfully");
      return true;
    } catch (error) {
      alertError(error);
    } finally {
      setisCommenting(false);
    }
  }, []);

  const deleteComment = useCallback(async (id, commentId) => {
    setisCommenting(true);
    try {
      await removeDisputeComment(id, commentId);
      alertSuccess("Comment removed successfully");
      return true;
    } catch (error) {
      alertError(error);
    } finally {
      setisCommenting(false);
    }
  }, []);

  return {
    fetchAll,
    allError,
    allDisputes,
    fetchSummary,
    summary,
    fetchSingle,
    singleDispute,
    fetchComments,
    comments,
    addComment,
    deleteComment,
    addFile,
    deleteFile,
    uploadBatch,
    uploadNew,
    updateStatus,
    isLoadingAll,
    isCommenting,
    isFetching,
    isFiling,
    isLoadingSingle,
    isLoadingSummary,
    isUpdating,
    isUploading,
  };
};

export default useDispute;
