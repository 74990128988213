import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const UnAuthenticated = () => {
  const isLoggedIn = useSelector((state) => state?.userAuth?.isLoggedIn);

  return !isLoggedIn ? <Outlet /> : <Navigate to="/overview" />;
};

export default UnAuthenticated;
