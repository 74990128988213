import { Box, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import CButton from "../buttons/CButton";

const key = window.location.origin + "_cookies";

const AcceptCookies = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const doesExist = sessionStorage.getItem(key);
    if (!doesExist) {
      setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    }
  }, []);

  const handleAccept = () => {
    sessionStorage.setItem(key, "true");
    setIsOpen(false);
  };

  return (
    <Drawer anchor='bottom' open={isOpen}>
      <Box
        width='auto'
        role='presentation'
        className='p-5 flex flex-col gap-2.5 border-t-4 border-purple'
      >
        <h3 className='text-black font-semibold text-lg text-center md:text-left'>
          We Use Cookies!
        </h3>
        <p className='text-black opacity-90 text-center md:text-left'>
          FCMB website uses cookies to ensure necessary functionality, improve your experience and
          analyze our traffic. By continuing into the website, you have given consent to our use of
          cookies. For more information read our{" "}
          <a
            className='text-purple underline hover:opacity-100'
            href='https://www.fcmb.com/privacy-policy'
            rel='noreferrer'
            target='_blank'
          >
            Privacy Policy
          </a>
        </p>
        <div className='flex gap-2.5 mt-2.5 md:mt-1 self-center md:self-end'>
          <CButton
            onClick={handleAccept}
            className='gradient !min-w-[140px] !h-[45px] !opacity-95 !hover:opacity-100'
          >
            Accept All
          </CButton>
          <CButton
            variant='outlined'
            onClick={() => setIsOpen(false)}
            className='!min-w-[140px] !text-grey !border-grey !h-[45px] hover:!bg-gray'
          >
            Reject All
          </CButton>
        </div>
      </Box>
    </Drawer>
  );
};

export default AcceptCookies;
