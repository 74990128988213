import { Box, Button, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";

import VisibilityIcon from "@mui/icons-material/Visibility";

import CustomTable from "./CustomTable";
import NoTableData from "./NoTableData";
import { AppRoutes } from "../../routes/AppRoutes";
import DateRange from "../search inputs/DateRange";
import InputSearch from "../search inputs/InputSearch";
import { numberWithCommas } from "../../utils/helperFunctions";
import { StyledTableCell, StyledTableRow } from "../../layouts/tables/TableLayout";

const DisputeTable = (props) => {
  const navigate = useNavigate();
  const [dateSearch, setDateSearch] = useState({});
  const [inputSearch, setInputSearch] = useState("");
  const { userType } = useSelector((state) => state?.userAuth?.data);

  const isStaff = userType?.toLowerCase() === "staff";

  const tableHeaders = [
    "Dispute ID",
    "Date Created",
    "Customer Name",
    "Transaction Reference",
    isStaff ? "Merchant ID" : null,
    "Amount",
    "Channel",
    "Status",
    "",
  ];

  const totalDisputes = (count) => {
    if (count < 1) {
      return "no dispute";
    } else if (count === 1) {
      return "1 dispute";
    } else {
      return `${count} disputes`;
    }
  };

  const searchDateParamHandler = (prop, date) => {
    if (prop === "startDate") {
      setDateSearch({
        ...dateSearch,
        minDate: date,
      });
    }
    if (prop === "endDate") {
      setDateSearch({
        ...dateSearch,
        maxDate: date,
      });
    }
  };

  const handleSearch = () => {
    const payload = { ...props.searchParams, ...dateSearch };

    // check if date has been cleared
    if (!dateSearch) {
      delete payload.minDate;
      delete payload.maxDate;
    }

    // search by dispute identifier
    if (inputSearch.startsWith("FN")) {
      payload.code = inputSearch;
      delete payload?.transactionRef;
      if (!isStaff) {
        delete payload?.merchantCode;
      }
      props?.onSearch(payload);
      return;
    }

    // search by merchant code for only staff
    if (isStaff && inputSearch.length === 7) {
      payload.merchantCode = inputSearch;
      delete payload?.transactionRef;
      delete payload?.code;
      props?.onSearch(payload);
      return;
    }

    // search by transaction ref
    if (inputSearch.length === 16) {
      payload.transactionRef = inputSearch;
      delete payload?.code;
      if (!isStaff) {
        delete payload?.merchantCode;
      }
      props?.onSearch(payload);
      return;
    }

    // reset search params if no conditions were met
    delete payload.transactionRef;
    delete payload?.code;
    if (isStaff) {
      delete payload?.merchantCode;
    }

    props?.onSearch(payload);
  };

  const viewAction = (data) => {
    navigate(AppRoutes.dispute(data?.id, data?.status?.toLowerCase()));
  };

  return (
    <Box>
      <div className="table-title p-4 font-semibold flex justify-between w-full admin-table-header gap-10">
        <h2>
          {props?.title}
          <br />
          {props.action && <span className="sub-title">{totalDisputes(props?.count)}</span>}
        </h2>
        {props.action && (
          <div className="search-options ">
            <div className="inputs flex gap-4 items-center cursor-pointer">
              <InputSearch
                className="inputSearch"
                width={250}
                placeholder="Search..."
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
              />
              <DateRange
                className="dateRange admin-transactions flex-grow md:flex-grow-0"
                width={150}
                setDate={searchDateParamHandler}
                onReset={() => setDateSearch(null)}
              />
              <Stack
                direction="row"
                gap="8px"
                className="action-buttons xl:ml-1 flex gap-4"
                sx={{ height: "40px", flexGrow: { xs: 1, sm: 0 } }}
              >
                <Button
                  sx={{
                    width: props?.export ? { sm: "110px", xl: "140px" } : "140px",
                    height: "100%",
                    flexGrow: { xs: 1, sm: 0 },
                  }}
                  variant="outlined"
                  disableElevation
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Stack>
            </div>
          </div>
        )}
        {props?.onViewAll && (
          <p className="font-[400] text-sm text-purple cursor-pointer" onClick={props.onViewAll}>
            View All
          </p>
        )}
      </div>
      {props?.tableData?.length < 1 && (
        <NoTableData
          link={props?.error && props?.onRefresh && "Refresh"}
          onClick={props?.error && props?.onRefresh ? props.onRefresh : null}
        />
      )}
      {props?.tableData?.length >= 1 && (
        <CustomTable headers={tableHeaders}>
          {props.tableData?.map((data) => (
            <StyledTableRow props={props} hover key={data?.id}>
              <StyledTableCell component="th" scope="row">
                {data?.code}
              </StyledTableCell>
              <StyledTableCell>{moment(data?.dateCreated).format("DD MMM YYYY")}</StyledTableCell>
              <StyledTableCell className="capitalize">
                {data?.customerName?.toLowerCase()}
              </StyledTableCell>
              <StyledTableCell>{data?.transactionReference}</StyledTableCell>
              {isStaff && <StyledTableCell>{data?.merchantCode}</StyledTableCell>}
              <StyledTableCell>{data?.currency?.toUpperCase()} {numberWithCommas(data?.amount)}</StyledTableCell>
              <StyledTableCell>{data?.channel}</StyledTableCell>
              <StyledTableCell id={data?.status?.toLowerCase()}>{data?.status}</StyledTableCell>
              <StyledTableCell>
                <IconButton
                  color="primary"
                  aria-label="view"
                  size="small"
                  onClick={() => viewAction(data)}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </CustomTable>
      )}
    </Box>
  );
};

export default DisputeTable;
