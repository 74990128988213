import { useState } from "react";
import { TextField, Button, IconButton } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import AppUploader from "../../../../components/shared/AppUploader";
import useDispute from "../../../../services/hooks/useDispute";
import Spinner from "../../../../utils/Spinner";

const SendMessage = ({ disputeId, onCommentPost, onFileAdd }) => {
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [isFile, setIsFile] = useState(false);
  const { addComment, addFile, isFiling, isCommenting } = useDispute();

  const handleSubmit = async () => {
    if (isFile) {
      const formData = new FormData();
      formData.append("files", file);

      const res = await addFile(disputeId, formData);
      if (res) {
        setFile(null);
        setIsFile(false);
        onFileAdd(disputeId);
      }
    } else {
      const res = await addComment(disputeId, { disputeId, content: comment });
      if (res) {
        setComment("");
        onCommentPost(disputeId);
      }
    }
  };

  return (
    <div className="w-full absolute left-0 bottom-0 bg-white z-10 border border-gray rounded-b-md">
      {isFile ? (
        <div className="w-full h-[90px] bg-gray relative">
          <AppUploader onSelect={(file) => setFile(file)} isLoading={isFiling} />
        </div>
      ) : (
        <TextField
          fullWidth
          multiline
          rows={3}
          value={comment}
          variant="filled"
          placeholder="comment..."
          id="filled-multiline-comment"
          onChange={(e) => setComment(e.target.value)}
          sx={{
            ".MuiInputBase-root": { pt: "12px", borderRadius: "0px" },
          }}
          disabled={isCommenting}
        />
      )}
      <div className="w-full px-2 py-1 flex justify-between items-center">
        {isFile ? (
          <IconButton
            aria-label="close"
            onClick={() => setIsFile(false)}
            size="small"
            disabled={isFiling}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <AttachFileIcon className="cursor-pointer text-[gray]" onClick={() => setIsFile(true)} />
        )}
        <Button
          variant="contained"
          onClick={handleSubmit}
          disableElevation
          endIcon={<SendIcon />}
          disabled={comment.length < 1 && !file}
        >
          {(isCommenting || isFiling) && <Spinner size={20} color="primary" />}
          Send
        </Button>
      </div>
    </div>
  );
};

export default SendMessage;
