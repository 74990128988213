import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import useDispute from "../../../services/hooks/useDispute";
import { AppRoutes } from "../../../routes/AppRoutes";
import Spinner from "../../../utils/Spinner";

const CloseDisputeModal = (props) => {
  const { open, onClose, disputeId } = props;

  const commentRef = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { updateStatus, isUpdating } = useDispute();

  const handleSubmit = async () => {
    setError(false);
    const comment = commentRef.current.value;
    const payload = { disputeId, newStatus: "closed", comment };

    if (comment.length === 0) {
      setError(true);
      return;
    }

    // update dispute status
    const res = await updateStatus(disputeId, payload);
    if (res) {
      onClose();
      navigate(AppRoutes.dispute(disputeId, "close"));
    }
  };

  return (
    <Dialog className="close-dispute-dialog" open={open} onClose={onClose} fullWidth maxWidth="xs">
      {isUpdating && <Spinner />}
      <DialogTitle
        sx={{ m: 0, p: 2, fontWeight: 600, fontSize: "18px" }}
        id="customized-dialog-title"
        className="!opacity-90"
      >
        Are you sure you want to close this dispute?
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          required
          multiline
          rows={4}
          onInput={() => {
            if (commentRef.current.value.length > 150) {
              commentRef.current.value = commentRef.current.value.slice(0, 150);
            }
          }}
          inputRef={commentRef}
          variant="outlined"
          placeholder="comment..."
          onChange={() => setError(false)}
          id="filled-multiline-comment"
          sx={{
            ".MuiInputBase-root": { pt: "12px", borderRadius: "3px" },
          }}
          error={error}
          helperText={error && "comment is required to close this dispute"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ minWidth: "150px", height: "45px", opacity: "30%" }}
          variant="outlined"
          disableElevation
          onClick={onClose}
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          sx={{ minWidth: "150px", height: "45px" }}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseDisputeModal;
