import { useSelector } from "react-redux";
import MerchantNotifications from "./merchant/Notifications";
import AdminNotifications from "./staff/Notifications";

const Notifications = () => {
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const isMerchant = userType?.toLowerCase()?.includes("merchant");

  return <>{isMerchant ? <MerchantNotifications /> : <AdminNotifications />}</>;
};

export default Notifications;
